<template>
    <div class="container">
        <!-- 海报分类 -->
        
      <!-- 文件名称搜索框 -->
      <!-- <div class="header-item">
        <el-input v-model="searchText" placeholder="请输入海报名称" suffix-icon="el-icon-search"
          @change="getList">
        </el-input>
      </div> -->
        <!-- 文件夹列表及操作 -->
      <div class="brochure-item">
        <el-table ref="multipleTable"  :data="tabData"  tooltip-effect="dark" style="width: 100%; margin-top: 10px" 
          header-row-class-name="table-header-style"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
          @selection-change="handleSelectionChange">
          <el-table-column  type="selection" width="55"></el-table-column>
          <el-table-column label="分类名称" align="center" width="200" prop="name">
            
          </el-table-column>
          <el-table-column label="海报数量">
            <template slot-scope="scope">
              <p @click="goPosterList(scope.row)" style="cursor: pointer;">{{scope.row.count}}</p>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="400" >
            <template slot-scope="scope">
              <p>{{scope.row.createdAt | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>

          <!-- 删除、重命名 -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button  type="text" style="color: #ff0000"  @click="updataPosterSatus(scope.row,'delete')">删除</el-button>
              <el-button type="text" @click="posterClassifyByIdEdit(scope.row)">编辑</el-button>
              <el-button type="text" v-show="scope.row.status == 0" @click="updataPosterSatus(scope.row,'online')">上架</el-button>
              <el-button type="text" v-show="scope.row.status == 1" @click="updataPosterSatus(scope.row,'downline')">下架</el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>
      <!-- 分页 -->
      <div class="setPage" style="margin-top: 20px; overflow: hidden">
        <!-- 批量操作按钮 -->
          <el-button :disabled="!multipleSelection" @click="updateMultipleStatus('release')">批量上架</el-button>
          <el-button :disabled="!multipleSelection" @click="updateMultipleStatus('dismount')">批量下架</el-button>
          <el-button :disabled="!multipleSelection" @click="updateMultipleStatus('delete')">批量删除</el-button>
        
        
        <div style="float: right">
          <el-pagination
            :background="true"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total,prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
     
    </div>
</template>
<script>
export default {
    data() {
      return{
        currentPage:1,
        pageSize:7,
        total:0,
        classifyData:[],//海报分类列表总数据
        tabData:[],//页面展示的数据
        searchText: '', // 海报分类搜索框字段
        searchStatus: 100, // 海报搜索状态
        status:0,//上线状态 1上架，0下架 -1删除
        multipleSelection: "", // 用户当前选中的海报ID组
        }
    },
    created() {
      //调用海报分类列表
      this.posterCategoryList();
      //分页
      this.handleCurrentChange();
    },
    methods: {
      //分页
      handleCurrentChange(val) {
        this.currentPage = val;
        this.tabData = this.classifyData.slice((this.currentPage - 1) * this.pageSize,this.currentPage * this.pageSize)
      },
      //获取海报分类列表
      async posterCategoryList() {
          let params = {
            type:51
          }
          let  res = await this.$ZGManager.materialCategoryList(params);
          // console.log('海报分类列表',res);
          if(res.status != 200) return;
          this.classifyData = res.data;
          this.total = this.classifyData.length;
          this.tabData = this.classifyData.slice(0,7)        
      },
      //根据海报分类ID修改海报分类
    async posterClassifyByIdEdit(row) {
      try {
        let dialog = await this.$prompt('请输入海报名称', {
          inputValue: row.name, 
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '海报名称不能为空',    
        })
        if(dialog.action != "confirm") return;
        let res = await this.$ZGManager.posterCategoryEdit(row.id,{name:dialog.value})
        if(res.status != 200) return;
        this.$message({
          type: 'success',
          message: '修改海报分类成功'
          });
        this.posterCategoryList();
        } catch (error) {
          console.log(error);
          }
      },
     //修改状态
     async updataPosterSatus(row,type) {
       let params = {};
      //  params.name = row.name;
       switch (type) {
         case 'delete':
           params.status = '-1';
           break;
         case 'online':
           params.status = '1';
           break;
         case 'downline':
           params.status = '0';
           break;
        default: status = row.status;
           break;
      }
      if(type == "delete"){
          let dialog = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });   
       }
        let res = await this.$ZGManager.posterCategoryEdit(row.id,params)
        if (res.status == 200) {     
        this.posterCategoryList();
        this.currentPage = 1;
      }     
     },
    //批量修改状态
     async updateMultipleStatus(type){
      let status = "";
      switch(type){
        case 'release': 
          status = "1";
          break;
        case 'dismount':
          status = "0";
          break;
        case 'delete':
          status = "-1";
          break;
        default: status = "";
      }
      if(type == "delete") {
        let dialog = await this.$confirm('此操作将永久删除该海报分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
         } 
        // if (dialog.action != 'confirm') {
        //   return
        // }
        let res = await this.$ZGManager.updataPosterCategoryStatus(this.multipleSelection, status);
        if(res.status == 200){
          this.posterCategoryList();
        }
      
    },
    // 选中海报后的处理
    handleSelectionChange(val) {
      this.multipleSelection = "";
      val.map(e => {
        this.multipleSelection += `${e.id},`
      });
      this.multipleSelection = this.multipleSelection.substring(0, this.multipleSelection.length - 1);
    },
    //点击海报数量跳转到海报列表
    goPosterList(row) {
      this.$emit("change",{
        name:'first',
        id:row.id
      })
    }
    },
}
</script>
<style scoped>
.breadcrumb{
  padding: 15px 10px;
  background-color: rgb(246, 247, 248);
  border-bottom: 1px solid #e8e8e8;
  margin-top: 10px;
  overflow: hidden;
}
.el-breadcrumb{
  width: 800px;
  display: inline-block;
}
.container {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
}
</style>