<template>
  <div class="container">
      <!-- 海报素材，海报分类page -->
    <div class="list-page">
      <el-tabs v-model="activeName" >
        <el-tab-pane label="海报素材" name="first">
            <p-list :goPosterListID ='goPosterListID' ref="list"></p-list>
        </el-tab-pane>
        <el-tab-pane label="海报分类" name="second">
          <p-classify ref="classify" @change="change" ></p-classify>
        </el-tab-pane>
      </el-tabs>
        <!-- 新增分类button -->
      <div class="add-classify" v-show="activeName == 'second'">
        <el-button icon="el-icon-plus"   type="text" @click="openAddClassify">新增分类</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import List from './List';
import Classify from './Classify';
export default {
    data() {
        return {
            //默认打开页面加载哪个组件和是否现实addclassify按钮
            activeName: 'first',
            // id:null
            goPosterListID:'',//从classify分类页面通过自定义事件传递过来的分类id
        }
    },
    components: {
        'p-list':List,
        'p-classify':Classify,
    },
    created() {
      // this.goPosterListID = this.$route.params.id;
    },
    methods: {
      async change(val){
        this.activeName=val.name;
        this.goPosterListID = val.id;
        // console.log(this.goPosterListID);
        // console.log(val)
        this.$refs.list.getList(0,this.goPosterListID);
      },
      
      //打开海报新增分类message
      async openAddClassify() {
        try {
          let dialog = await this.$prompt('请输入类目名称',  {
          confirmButtonText: '确定',
          cancelButtonText: '取消',  
          inputPattern:/^[\u4E00-\u9FA5]{2,4}(·[\u4E00-\u9FA5]{1,4})?$/,
          inputErrorMessage: '名称大于2位小于4位',
          // inputValue: '请输入类目名称'
        });
          if(dialog.action != 'confirm') return;
          let params = {
            type:51,
            name:dialog.value
          }
          // let res = await this.$ZGManager.posterCategoryAdd({name:dialog.value});
          let res = await this.$ZGManager.posterCategoryAdd(params);
          if(res.status != 200) return;
          this.$message({
            type: 'success',
            message: '添加海报成功'
          });
          this.$refs.classify.posterCategoryList();
        } catch (error) {
          console.log(error);
        }
    },
    }
}
</script>
<style scoped>
.container {
  padding: 10px;
  background-color: #fff;
  /* margin-bottom: 10px; */
  font-size: 14px;
  position: relative;
}
.list-page {
  line-height: 60px;
}
/* 该ui样式加 ::v-deep 或者 >>> */
.list-page ::v-deep .el-tabs__item{
  text-align: center;
  width: 100px!important;
}

.add-classify {
    position: absolute;
    top: 10px;
    right: 40px;
}
.add-classify button {
  background: #409eff;
  color: #fff;
  width: 117px;
  height: 35px;
}
</style>